import React from 'react';
import './App.css';

// Imports the Amplify library from 'aws-amplify' package
import { Amplify } from 'aws-amplify';

// Imports the Authenticator and withAuthenticator components
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

// Imports the default styles for the Amplify UI components
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file
import awsExports from './aws-exports';

// Configures the Amplify library with the settings from aws-exports.js
Amplify.configure(awsExports);

function App() {
  return (
    <div className="App">
      <Authenticator>
        {({ signOut }) => (
          <main>
            <header className='App-header'>
              {/* Welcome Message */}
              <h1>Welcome to Contractors Eddie</h1>
              
              {/* Sign Out Button */}
              <button 
                onClick={signOut} 
                style={{ 
                  margin: '20px', 
                  fontSize: '0.8rem', 
                  padding: '5px 10px', 
                  marginTop: '20px'
                }}
              >
                Sign Out
              </button>
            </header>
          </main>
        )}
      </Authenticator>
    </div>
  );
}

export default withAuthenticator(App);